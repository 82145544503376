ion-app.web ion-item.screenshots {
  display: none !important;
}

ion-content.no-scroll {
  --overflow: hidden;
}

ion-content ion-toolbar {
  --background: transparent;
}

ion-content.player main {
  background: black;
}

.edit-button-text {
  padding: 0px 10px;
  cursor: default;
}

.copyright-version {
  padding: 0px 10px;
  font-size: 0.9rem;
}

.viewmedica-ionic-player {
  width: 100%;
  height: 100%;
}

.viewmedica-ionic-player .Viewmedica.Viewmedica--eight,
.viewmedica-ionic-player .Viewmedica.Viewmedica--nine {
  width: 100%;
  height: 100%;
}

@media (max-width: 420px) {
  .viewmedica-ionic-player {
    padding-bottom: constant(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-top);
  }
}

.viewmedica-ionic-player iframe {
  width: 100%;
  height: 100%;
  border: none !important;
}

ion-title ion-img {
  max-width: 140px;
  padding: 0;
  margin: 0 auto;
}

#empty-favorites,
#no-internet {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  padding: 0 40px;
}

#empty-favorites h2,
#no-internet {
  color: gray;
}
#no-internet-login {
  color: white;
}

#login-page {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #009ed9;
}

#login-form {
  max-width: 250px;
}

.login-error {
  color: white !important;
  text-align: center;
  margin-top: 0;
}

#login-page input {
  background: white;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}

ion-input {
  color: black !important;
}

.loading-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#button-container {
  display: flex;
  justify-content: center;
}

@media (orientation: landscape) {
  .viewmedica-ionic-player {
    /* padding-left: constant(safe-area-inset-left);
    padding-left: env(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-right: env(safe-area-inset-right); */
    background: black !important;
  }
}

#player-content {
  position: relative;
}

#top-bar-facade {
  height: 28px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid #dedede;
  display: none;
}

#bottom-bar-facade {
  height: 34px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
}

#login-page input {
  pointer-events: auto !important;
}

.player-logo {
  background: white;
  padding: 0 20px;
  border-radius: 40px;
}

#practice-name {
  margin: 20px;
  display: block;
}
